import { getLandingCounselors } from 'actions/counselorAction';
import { isApp, isIOSApp } from 'actions/referrer';

import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'routers/PrivateRoute.js';
import { fetchState } from '../actions';
import Loader from './../pages/Loader';
// a function to retry loading a chunk to avoid chunk load error for out of date code
import lazyRetry from './../utils/lazyRetry';

import LandingPageIelts from 'pages/LandingPageIelts.js';

const VerificationPage = React.lazy(() => lazyRetry(() => import('pages/VerificationPage.js'), 'VerificationPage'));

const LandingPageNewCounselor = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "LandingPageCounselor" */ 'pages/LandingPageCounselor/LandingPage'),
    'LandingPageNewCounselor'
  )
);

const LandingPageNewShortlister = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "LandingPageCounselor" */ 'pages/LandingPageShortlister/LandingPage'),
    'LandingPageNewCounselor'
  )
);

const LandingPageNew = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "LandingPage" */ 'pages/LandingPage/LandingPage'), 'LandingPageNew')
);

const LoginPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "LoginPage" */ 'pages/LoginPage.js'), 'LoginPage')
);
const LoginPageBeta = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "LoginPageBeta" */ 'pages/LoginPageBeta.js'), 'LoginPageBeta')
);

const LandingPagePartners = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "LandingPagePartners" */ 'pages/LandingPagePartners.js'),
    'LandingPagePartners'
  )
);

const PricePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "PricePage" */ 'pages/PricePage.js'), 'PricePage')
);

const Tests = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "Tests" */ 'pages/Tests.js'), 'Tests'));
const FullCourse = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "FullCourse" */ 'pages/FullCourse.js'), 'FullCourse')
);

const Pricing = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Pricing" */ 'pages/pricing.js'), 'Pricing')
);

const PricingSuccess = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "PricingSuccess" */ 'pages/PricingSuccess.js'), 'PricingSuccess')
);
const PricingFailure = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "PricingFailure" */ 'pages/PricingFailure.js'), 'PricingFailure')
);

const RecordingIelts = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "RecordingIelts" */ 'pages/RecordingIelts.js'), 'RecordingIelts')
);
const ReadingIelts = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ReadingIelts" */ 'pages/ReadingIelts.js'), 'ReadingIelts')
);
const ReadingIeltsRound = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ReadingIeltsRound" */ 'pages/ReadingIeltsRound.js'), 'ReadingIeltsRound')
);
const ListeningIelts = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ListeningIelts" */ 'pages/ListeningIelts.js'), 'ListeningIelts')
);
const ListeningIeltsRound = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "ListeningIeltsRound" */ 'pages/ListeningIeltsRound.js'),
    'ListeningIeltsRound'
  )
);
const WritingIelts = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "WritingIelts" */ 'pages/WritingIelts.js'), 'WritingIelts')
);

const Admin = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "Admin" */ 'pages/Admin.js'), 'Admin'));
const PrivacyPolicy = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "PrivacyPolicy" */ 'pages/privacy_policy.js'), 'Privacy_Policy')
);
const OfficialPrivacyPolicy = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OfficialPrivacyPolicy" */ 'pages/official_privacy_policy.js'),
    'Official_Privacy_Policy'
  )
);
const OfficialCookiePolicy = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OfficialCookiePolicy" */ 'pages/official_cookie_policy.js'),
    'Official_Cookie_Policy'
  )
);
const OfficialTermsPolicy = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OfficialTermsPolicy" */ 'pages/official_terms_policy.js'),
    'Official_Terms_Policy'
  )
);
const OfficialRefundPolicy = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OfficialRefundPolicy" */ 'pages/official_refund_policy.js'),
    'Official_Refund_Policy'
  )
);
const HowItWorksStandalone = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "HowItWorks" */ 'pages/HowItWorksStandalone.js'), 'HowItWorksStandalone')
);

const Onboarding = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Onboarding" */ 'pages/Onboarding.js'), 'Onboarding')
);
const RegisterPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Register" */ 'pages/RegisterPage.js'), 'RegisterPage')
);
const ResetPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Reset" */ 'pages/ResetPage.js'), 'ResetPage')
);
const EmailVerification = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "EmailVerification" */ 'pages/emailVerification.js'), 'EmailVerification')
);

const ContactUsPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ContactUs" */ 'pages/ContactUs.js'), 'ContactUsPage')
);

const RegisterApp = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "RegisterApp" */ 'pages/RegisterApp.js'), 'RegisterApp')
);

const StartIelts = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "StartIelts" */ 'pages/StartIelts.js'), 'StartIelts')
);

const RegisterPageReferral = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "RegisterPageReferral" */ 'pages/RegisterPageReferral.js'),
    'RegisterPageReferral'
  )
);

const ShortlisterPageNoAuth = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "StartIelts" */ 'pages/ShortlisterPage/ShortlisterPageNoAuth.jsx'),
    'ShortlisterPageNoAuth'
  )
);

const AppRouter = props => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isVerifying = useSelector(state => state.auth.isVerifying);
  const isOnboarded = useSelector(state => state.auth.hasOnboarded);
  const counselors = useSelector(state => state.counselors.counselors);
  const isOnboardingVerifying = useSelector(state => state.auth.hasOnboardedVerify);

  const dispatch = useDispatch();
  const currentPath = window.location.pathname;

  if (!isAuthenticated || !isVerifying) {
    if (currentPath === '/admin/join_class') {
      localStorage.setItem('currentPath', '/admin/join_class');
    }

    if (currentPath === '/admin/special-offer') {
      localStorage.setItem('currentPath', '/admin/special-offer');
    }
  }

  React.useEffect(() => {
    dispatch(fetchState());
  }, [dispatch]);

  React.useEffect(() => {
    if (window.location.pathname.includes('admin')) {
      document.querySelector('body').style.overflow = 'visible';
    }
    localStorage.setItem('isRefreshed', false);
  }, []);

  React.useEffect(() => {
    const mobHeight = () => {
      let timer;

      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        let vh = window.innerHeight * 0.01;

        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 500);
    };
    mobHeight();

    window.addEventListener('resize', mobHeight);
    return () => window.removeEventListener('resize', mobHeight);
  }, []);

  React.useEffect(() => {
    if (counselors.length === 0) {
      dispatch(getLandingCounselors());
    }
  }, [counselors.length, dispatch]);
  return (
    <Switch>
      <Route
        path="/"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              {(isAuthenticated === null || isOnboarded === null) && <Loader />}
              {isAuthenticated === false && <FirstPage />}
              {isAuthenticated === true && isOnboarded === false ? <OnboardingPage /> : <Admin />}
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/about-us"
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <ContactUsPage />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/verify/:slug"
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <VerificationPage />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/email-verify"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <EmailVerification />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/admin"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <Admin />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/readingielts-mock/:id/attempt/:attemptId"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <ReadingIelts isMock={true} />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/readingielts/:id/attempt/:attemptId"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <ReadingIeltsRound isMock={false} />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/writingielts/:id/:assesment_id"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <WritingIelts />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/listeningielts-mock/:id/attempt/:attemptId"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <ListeningIelts isMock={true} />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/listeningielts/:id/attempt/:attemptId"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <ListeningIeltsRound isMock={false} />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/recordingielts/:id"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <RecordingIelts isMock={false} />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/recording-mock/:id/:attemptId"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <RecordingIelts isMock={true} />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/pricing"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <Pricing />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/unsignedpricing"
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>{/* <UnsignedPricing /> */}</Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/pricingsuccess/:id"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <PricingSuccess />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/pricingfailure/:id"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <PricingFailure />
            </Suspense>
          </div>
        )}
      />

      <PrivateRoute
        path="/startielts"
        isAuthenticated={isAuthenticated}
        isOnboarded={isOnboarded}
        isVerifying={isVerifying}
        isOnboardingVerifying={isOnboardingVerifying}
        component={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <StartIelts />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/partners"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <LandingPagePartners />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/tests"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <Tests />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/fullcourse"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <FullCourse />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/ielts"
        exact={true}
        render={() => (
          <>
            <Suspense fallback={<Loader />}>
              <LandingPageIelts />
            </Suspense>
          </>
        )}
      />

      <Route
        path="/counseling"
        exact={true}
        render={() => (
          <>
            <Suspense fallback={<Loader />}>
              <LandingPageNewCounselor />
            </Suspense>
          </>
        )}
      />

      <Route
        path="/shortlister"
        exact={true}
        render={() => (
          <>
            <Suspense fallback={<Loader />}>
              <LandingPageNewShortlister />
            </Suspense>
          </>
        )}
      />

      <Route
        path="/default"
        // exact={true}
        render={() => (
          <>
            <Suspense fallback={<Loader />}>
              <LandingPageNew />
            </Suspense>
          </>
        )}
      />

      <Route
        path="/plan-information"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <PricePage />
            </Suspense>
          </div>
        )}
      />
      <Route
        path="/register-app"
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <RegisterApp />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/login-page"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <LoginPage />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/login-page-beta"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <LoginPageBeta />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/signup"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <RegisterPage />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/signup/referrer/:referrer"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <RegisterPageReferral />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/loading"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <Loader />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/onboarding"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <Onboarding />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/reset"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <ResetPage />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/privacy_policy"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <PrivacyPolicy />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/official_privacy_policy"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <OfficialPrivacyPolicy />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/official_cookie_policy"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <OfficialCookiePolicy />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/official_terms_policy"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <OfficialTermsPolicy />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/official_refund_policy"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <OfficialRefundPolicy />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/how_it_works"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <HowItWorksStandalone />
            </Suspense>
          </div>
        )}
      />

      <Route
        path="/shortlister-login"
        exact={true}
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <ShortlisterPageNoAuth />
            </Suspense>
          </div>
        )}
      />

      <Route
        render={() => (
          <div>
            <Suspense fallback={<Loader />}>
              <FirstPage />
            </Suspense>
          </div>
        )}
      />
    </Switch>
  );
};

export default AppRouter;

const FirstPage = () => {
  return isApp() || isIOSApp() ? <RegisterApp /> : <LandingPageIelts />;
};

const OnboardingPage = () => {
  return isApp() || isIOSApp() ? <RegisterApp /> : <RegisterPage />;
};
