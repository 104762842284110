import api from 'services/api';

export function getWebinars() {
  return api.get('/schedule/events');
}

export function getWebinarById(id) {
  return api.get(`/schedule/events/${id}`);
}

export function trackEvent(eventId) {
  if (eventId == null || eventId === undefined) {
    throw new Error('missing required parameters');
  }
  return api.get(`schedule/events/track/${eventId}`);
}

export function registerEvent(eventId) {
  if (eventId == null || eventId === undefined) {
    throw new Error('missing required parameters');
  }
  return api.get(`schedule/events/register/${eventId}`);
}

export function getNextWebinarContent() {
  return api.get('/schedule/banners');
}
