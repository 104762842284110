import { getNews } from 'api/newsApi';

export const LOADING_NEWS_START = 'LOADING_NEWS_START';
export const LOADING_NEWS_SUCCESS = 'LOADING_NEWS_SUCCESS';
export const LOADING_NEWS_FAILED = 'LOADING_NEWS_FAILED';

export const loadingNewsStartAction = () => {
  return {
    type: LOADING_NEWS_START,
  };
};

export const loadingNewsSucessAction = counselors => {
  return {
    type: LOADING_NEWS_SUCCESS,
    payload: counselors,
  };
};

export const loadingNewsFailedAction = e => {
  return {
    type: LOADING_NEWS_FAILED,
    payload: e,
  };
};

export const getAllNews = () => async dispatch => {
  try {
    dispatch(loadingNewsStartAction());

    const res = await getNews({}); // Call your API function here
    dispatch(loadingNewsSucessAction(res.data));
  } catch (error) {
    dispatch(loadingNewsFailedAction(error));
  }
};
