import api from 'services/api';

export async function getAllShortlisters() {
  return await api.get('shortlister');
}

export async function getShortlisterById(id) {
  return await api.get(`shortlister/${id}`);
}

export async function getAllCoursesById(univ_id) {
  return await api.get(`shortlister/course/${univ_id}`);
}

export async function createShortlist(data) {
  return await api.post('shortlister', data);
}

export async function getUniversityById(university_id) {
  return await api.get(`shortlister/universities/${university_id}`);
}

export async function getAllCoursesFromShortlistUni(shortlist_id, university_id) {
  return await api.get(`shortlister/${shortlist_id}/university/${university_id}/courses`);
  // return await api.get(`shortlister/university/${university_id}/course`);
}

export async function wishlistCourse(
  course_id,
  type,
  title,
  country,
  rank_qs,
  location,
  logo_s3_link,
  courses,
  application_deadline
) {
  return await api.put(`shortlister/shortlist`, {
    course_id,
    type,
    title,
    country,
    rank_qs,
    location,
    logo_s3_link,
    courses,
    application_deadline,
  });
}

export async function removeCourseFromWishlist(course_id) {
  return await api.put(`shortlister/shortlist`, { course_id: course_id, type: 'remove' });
}

export async function getWishList() {
  return await api.get(`shortlister/shortlist`);
}

export async function getCoursesById(course_id) {
  return await api.get(`/shortlister/course/${course_id}`);
}
